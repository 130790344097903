<a
  class="type-body-1 inline-flex flex-row items-center text-xs"
  [title]="content?.link?.title"
  [target]="content?.link?.target"
  [href]="content?.link?.href"
  [id]="content?.id"
  (click)="handleClick($event)"
>
  <span class="breadcrumbs-text-link-href-text" [ngClass]="{ 'text-cg-red': last }">{{ content?.link?.text }}</span>
  <cg-icon class="[.last]:hidden mx-1 h-4 w-4" [ngClass]="{ invisible: last }" [content]="content.icon"></cg-icon>
</a>
