import { UserCentricsService } from "@cg/consent-management";
import { environment } from "@cg/environments";
import { arrowsIcon, facebookIcon, twitterIcon, xingIcon, youtubeIcon } from "@cg/icon";
import { CtaVariation } from "@cg/shared";
import type { Footer } from "@cg/core/interfaces";

export const footerContent: Footer = {
  headline: "Wir reparieren Ihren Glasschaden",
  cta: {
    id: "test-cta",
    title: "Jetzt Termin vereinbaren",
    icon: arrowsIcon,
    link: {
      text: "Jetzt Termin vereinbaren",
      href: "/olb"
    },
    variation: CtaVariation.SECONDARY,
    arrowDirection: "right",
    ngTemplate: "cgCta"
  },
  navigations: [],
  meta: {
    social: {
      text: "Sie finden uns auch auf:",
      links: [
        {
          href: "https://www.facebook.com/CarglassDeutschland/",
          title: "Facebook",
          target: "_blank",
          icon: facebookIcon
        },
        {
          href: "https://twitter.com/carglass_de",
          title: "Twitter",
          target: "_blank",
          icon: twitterIcon
        },
        {
          href: "https://www.youtube.com/user/CarglassDeutschland",
          title: "YouTube",
          target: "_blank",
          icon: youtubeIcon
        },
        {
          href: "https://www.xing.com/companies/carglassdeutschlandgmbh",
          title: "Xing",
          target: "_blank",
          icon: xingIcon
        }
      ]
    },
    metaNavigation: [
      {
        text: "Impressum",
        href: `${environment.footerLinkBase}/impressum`
      },
      {
        text: "AGB",
        href: `${environment.footerLinkBase}/agb`
      },
      {
        text: "Datenschutz",
        href: `${environment.footerLinkBase}/datenschutz`
      },
      {
        text: "Privatsphäre-Einstellungen",
        href: undefined,
        onclick: (event: Event) => {
          event.preventDefault();
          UserCentricsService.openSecondLayer();
        }
      },
      {
        text: "Kontakt",
        href: `${environment.footerLinkBase}/kontakt`
      }
    ],
    metaNavigationClass: "meta-navigation-footer"
  }
};
