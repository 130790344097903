<ul class="nav nav-meta" [ngClass]="metaNavClass">
  @for (item of metaNavigation; track item) {
    <li class="nav-item">
      <a
        class="nav-link"
        [attr.title]="item.title"
        [target]="item.target || '_blank'"
        [class.active]="isActive(item.href)"
        [class.active-sub-level]="isSubLevelActive"
        (click)="item.onclick ? item.onclick($event) : null"
        href="{{ item.onclick ? null : item.href }}"
        [ngClass]="{ '!text-white': hasError }"
        >{{ item.text }}</a
      >
    </li>
  }
</ul>
