import { createAction, props } from "@ngrx/store";
import type { NavigationStructure } from "../interfaces/navigation-structure.interface";

export const getHeaderNavigationStructure = createAction("[NavigationAction] Get Header Navigation Structure");
export const getHeaderNavigationStructureSuccess = createAction(
  "[NavigationAction] Get Header Navigation Structure Success",
  props<{ payload: NavigationStructure }>()
);
export const getHeaderNavigationStructureFailure = createAction(
  "[NavigationAction] Get Header Navigation Structure Failure",
  props<{ error: string }>()
);

export const getFooterNavigationStructure = createAction("[NavigationAction] Get Footer Navigation Structure");
export const getFooterNavigationStructureSuccess = createAction(
  "[NavigationAction] Get Footer Navigation Structure Success",
  props<{ payload: NavigationStructure }>()
);
export const getFooterNavigationStructureFailure = createAction(
  "[NavigationAction] Get Footer Navigation Structure Failure",
  props<{ error: string }>()
);

export const setCurrentNavigation = createAction(
  "[NavigationAction] Set Current Navigation",
  props<{ payload: unknown }>()
);
