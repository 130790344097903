import { type Action, createReducer, on } from "@ngrx/store";
import { replaceCopyrightText } from "@cg/core/utils";
import type { CurrentNavigation } from "../interfaces/current-navigation.interface";
import type { NavigationStructure } from "../interfaces/navigation-structure.interface";
import * as NavigationActions from "./navigation.actions";

export const NAVIGATION_FEATURE_KEY = "navigation";

export interface NavigationState {
  header: NavigationStructure;
  currentNavPath?: CurrentNavigation | null;
  footer: NavigationStructure;
  loaded: boolean;
  headerError?: string | null;
  footerError?: string | null;
}

export interface NavigationPartialState {
  readonly [NAVIGATION_FEATURE_KEY]: NavigationState;
}

export const initialState: NavigationState = {
  header: null,
  footer: null,
  currentNavPath: null,
  loaded: false,
  headerError: null,
  footerError: null
};

const navigationReducer = createReducer(
  initialState,
  on(
    NavigationActions.getHeaderNavigationStructureSuccess,
    (state: NavigationState, { payload }: { payload: NavigationStructure }) => ({
      ...state,
      header: replaceCopyrightText<NavigationStructure>(payload),
      headerError: null,
      loaded: true
    })
  ),
  on(NavigationActions.getHeaderNavigationStructureFailure, (state: NavigationState, { error }: { error: string }) => ({
    ...state,
    headerError: error
  })),
  on(
    NavigationActions.getFooterNavigationStructureSuccess,
    (state: NavigationState, { payload }: { payload: NavigationStructure }) => ({
      ...state,
      footer: replaceCopyrightText<NavigationStructure>(payload),
      footerError: null,
      loaded: true
    })
  ),
  on(NavigationActions.getFooterNavigationStructureFailure, (state: NavigationState, { error }: { error: string }) => ({
    ...state,
    footerError: error
  }))
);

export function reducer(state: NavigationState | undefined, action: Action) {
  return navigationReducer(state, action);
}
