<div
  class="absolute right-10 top-[calc(50%-1px)] -translate-y-1/2 before:x-[content-[''],absolute,top-0,right-[0.65rem],border-l,border-cg-anthracite-light-80] hover:cursor-pointer active:cursor-pointer w:x-[relative,right-0,ml-9,flex,translate-y-0,items-center,gap-3.5,pb-1.5] w:before:x-[-left-6,bottom-2,right-auto,top-0.5] [&>a]:hover:text-cg-red [&>a]:active:text-cg-red [&>cg-icon>mat-icon>svg>path]:hover:x-[fill-cg-red,stroke-cg-red] [&>cg-icon>mat-icon>svg>path]:active:x-[fill-cg-red,stroke-cg-red]"
  (click)="navigateTo(item.href)"
  data-cy="logout-item"
>
  <cg-icon class="h-6 w-6" [content]="logoutIcon"></cg-icon>
  @if (!hideLinkElement) {
    <a
      class="text-cg-anthracite w:right-0"
      routerLinkActive="active"
      title="{{ item.title }}"
      target="{{ item.target || '_self' }}"
    >
      <span>{{ item.text }}</span>
    </a>
  }
</div>
