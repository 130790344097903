import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { RouterLink } from "@angular/router";
import { FooterNavigation, Link } from "@cg/core/interfaces";
import { SafeContentPipe } from "@cg/shared";
import { NavigationLinkTextComponent } from "../navigation-link-text/navigation-link-text.component";

// workaround for redirect problem
const redirectableParts = [
  { orig: "/autoglas/standorte", dest: "/standorte" },
  { orig: "/autoglas/reentry", dest: "/reentry" }
];

@Component({
  selector: "cg-footer-navigation",
  templateUrl: "./footer-navigation.component.html",
  styleUrls: ["./footer-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SafeContentPipe, NavigationLinkTextComponent, RouterLink]
})
export class FooterNavigationComponent implements OnInit {
  // TODO: CP-13564 workaround for redirect problem
  private _navigation: FooterNavigation;

  @Input()
  public set navigation(value: FooterNavigation) {
    value.links?.forEach((link: Link, index: number) => {
      redirectableParts.forEach((redirect: { orig: string; dest: string }) => {
        if (link.href.includes(redirect.orig)) {
          const newLinkUrl = link.href.replace(new RegExp(redirect.orig), redirect.dest);

          value.links[index] = { ...link, href: newLinkUrl };
        }
      });
    });

    this._navigation = value;
  }

  public get navigation(): FooterNavigation {
    return this._navigation;
  }

  public sanitizedContent: SafeHtml;

  public constructor(private domSanitizer: DomSanitizer) {}

  public ngOnInit() {
    this.navigation.links = this.flagExternalLinks(this.navigation.links);
    this.sanitizedContent = this.domSanitizer.bypassSecurityTrustHtml(this.navigation.headline);
  }

  public flagExternalLinks(links: Link[]) {
    return links.map((link: Link) => {
      if (link.href.includes("http")) {
        return { ...link, external: true };
      }

      return link;
    });
  }
}
