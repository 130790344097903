import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "cg-toggle-button",
  templateUrl: "./toggle-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass]
})
export class ToggleButtonComponent {
  @Output() public clickOpen = new EventEmitter<unknown>();
  @Output() public clickClose = new EventEmitter<unknown>();
  @Input() public set visible(visible: boolean) {
    this._visible = visible;
  }

  public get visible(): boolean {
    return this._visible;
  }

  private _visible: boolean;

  public toggle() {
    this._visible = !this._visible;
    if (this._visible) {
      this.clickOpen.emit();
    } else {
      this.clickClose.emit();
    }
  }
}
