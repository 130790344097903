import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "cg-navigation-link-text",
  templateUrl: "./navigation-link-text.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class NavigationLinkTextComponent {
  @Input()
  public text: string;
}
