import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { arrowSmallIcon } from "@cg/icon";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import { BreadcrumbsTextLinkComponent } from "../breadcrumbs-text-link/breadcrumbs-text-link.component";

@Component({
  selector: "cg-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, BreadcrumbsTextLinkComponent]
})
export class BreadcrumbsComponent {
  @Input()
  public data;
  @Input()
  public color;
  public route: unknown;

  public icon: Icon = arrowSmallIcon;

  public constructor(private router: Router) {
    this.route = router.routerState.snapshot.url;
  }
}
