@if (brand === application.CARGLASS) {
  <div class="header-wrapper header-wrapper-nav">
    <cg-main-navigation
      [mainNavigation]="header.mainNavigation"
      [metaNavigation]="header.metaNavigation"
      [currentNavigation]="currentNav"
      [route]="route?.state"
    ></cg-main-navigation>
  </div>
  @if (showEpidemicHint && (coronaToastHidden$ | async) === false && pathIsNotOlb) {
    <cg-toast-message [content]="toastContent" (closed)="coronaToastClosed()"></cg-toast-message>
  }
  @if (pathIsNotOlb && (busyBannerHidden$ | async) === false && (isBusyBannerVariationActive$ | async)) {
    <cg-busy-banner></cg-busy-banner>
  }
  @if (isShowBreadcrumbs) {
    <div class="header-wrapper header-wrapper-breadcrumbs hidden t:block">
      <div class="header-wrapper-breadcrumbs-content">
        <cg-breadcrumbs class="w-full" [color]="headingColor" [data]="currentNav"></cg-breadcrumbs>
      </div>
    </div>
  }
} @else {
  <div class="header-wrapper header-wrapper-nav">
    <cg-main-micro-navigation
      [mainNavigation]="header.mainNavigation"
      [currentNavigation]="currentNav"
      [route]="route?.state"
    ></cg-main-micro-navigation>
  </div>
  @if (isShowBreadcrumbs) {
    <div class="header-wrapper header-wrapper-breadcrumbs hidden t:block">
      <div class="header-wrapper-breadcrumbs-content">
        <cg-breadcrumbs class="w-full" [color]="headingColor" [data]="currentNav"></cg-breadcrumbs>
      </div>
    </div>
  }
}
