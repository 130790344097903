<div class="toast">
  <div
    class="toast-wrapper"
    part="toast-wrapper"
    [ngStyle]="{ 'background-color': content?.color }"
    [ngClass]="{ animated: content?.animated, 'rounded-corners': content?.roundedCorners }"
  >
    <div class="content-wrapper" [ngClass]="{ animated: content?.animated }">
      <cg-icon class="h-6 w-6" [content]="content?.icon"></cg-icon>

      <div class="text-wrapper">
        @if (content?.title) {
          <cg-headline [type]="headlineType.h3" [content]="content.title"></cg-headline>
        }

        <div class="text-wrapper--paragraph">
          <cg-paragraph
            [content]="{ text: content?.message, ngTemplate: 'cgParagraph' }"
            class="toast--paragraph"
          ></cg-paragraph>
          @if (content?.infoLink) {
            <a [routerLink]="content?.infoLink?.href" class="info-link">
              {{ content?.infoLink?.text }}
            </a>
          }
        </div>
      </div>

      @if (content?.closable) {
        <div class="spacer"></div>
        <div class="close">
          <cg-icon class="h-6 w-6" [content]="closeIcon" (click)="close()"></cg-icon>
        </div>
      }
    </div>
  </div>
</div>
