import { type Action, createReducer, on } from "@ngrx/store";
import * as HeaderActions from "./header.actions";

export const HEADER_FEATURE_KEY = "header";

export interface State {
  coronaToastHidden: boolean;
  busyBannerHidden: boolean;
}

export interface HeaderPartialState {
  readonly [HEADER_FEATURE_KEY]: State;
}

export const initialState: State = {
  coronaToastHidden: false,
  busyBannerHidden: false
};

const headerReducer = createReducer(
  initialState,
  on(HeaderActions.setCoronaToastHidden, (state: State) => ({ ...state, coronaToastHidden: true })),
  on(HeaderActions.setBusyBannerHidden, (state: State) => ({ ...state, busyBannerHidden: true }))
);

export function reducer(state: State | undefined, action: Action) {
  return headerReducer(state, action);
}
