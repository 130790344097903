import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { IconComponent } from "@cg/core/ui";
import { BreadcrumbsTextLink } from "../../interfaces/breadcrumbs-text-link.interface";

@Component({
  selector: "cg-breadcrumbs-text-link",
  templateUrl: "./breadcrumbs-text-link.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, IconComponent]
})
export class BreadcrumbsTextLinkComponent {
  @Input() public content: BreadcrumbsTextLink;
  @Input() public last: boolean;

  public constructor(private readonly router: Router) {}

  public handleClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.content.routerLink) {
      this.router.navigate([this.content.link.href]);
    } else {
      window.open(this.content.link.href, this.content.link.target ?? "_self");
    }
  }
}
