<h4 class="headline-footer-link" [innerHtml]="sanitizedContent"></h4>
<ul class="list-unstyled">
  @for (link of navigation.links; track link) {
    <li>
      @if (!link.external) {
        <a class="footer-link" routerLink="{{ link.href }}"
          ><cg-navigation-link-text [text]="link.text"></cg-navigation-link-text
        ></a>
      }
      @if (link.external) {
        <a class="footer-link" href="{{ link.href }}" [innerHTML]="link.text | safeContent"></a>
      }
    </li>
  }
</ul>
