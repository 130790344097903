import { provideHttpClient, withFetch } from "@angular/common/http";
import { type EnvironmentProviders, importProvidersFrom, type Provider } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { provideEffects } from "@ngrx/effects";
import { provideState } from "@ngrx/store";
import { NavigationEffects } from "./+state/navigation.effects";
import * as fromNavigation from "./+state/navigation.reducer";

export function provideNavigation(): Provider[] | EnvironmentProviders[] {
  return [
    importProvidersFrom(MatIconModule),
    provideHttpClient(withFetch()),
    provideState(fromNavigation.NAVIGATION_FEATURE_KEY, fromNavigation.reducer),
    provideEffects([NavigationEffects])
  ];
}
