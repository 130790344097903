import { createFeatureSelector, createSelector } from "@ngrx/store";
import { NAVIGATION_FEATURE_KEY, type NavigationState } from "./navigation.reducer";

// Lookup the 'Navigation' feature state managed by NgRx
export const getNavigationState = createFeatureSelector<NavigationState>(NAVIGATION_FEATURE_KEY);

export const getNavigationHeader = createSelector(getNavigationState, (state: NavigationState) => state.header ?? null);

export const getNavigationFooter = createSelector(getNavigationState, (state: NavigationState) => state.footer ?? null);

export const getNavigationLoaded = createSelector(getNavigationState, (state: NavigationState) => state.loaded);

export const getNavigationHeaderError = createSelector(
  getNavigationState,
  (state: NavigationState) => state.headerError
);
export const getNavigationFooterError = createSelector(
  getNavigationState,
  (state: NavigationState) => state.footerError
);
