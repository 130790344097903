<nav class="navbar navbar-toggleable-md">
  <cg-toggle-button
    class="hamburger"
    (clickOpen)="onOpen()"
    (clickClose)="onClose()"
    [visible]="visible"
  ></cg-toggle-button>
  <a
    routerLink="{{ mainNavigation?.brand?.link?.href }}"
    class="navbar-brand"
    target="{{ mainNavigation?.brand?.link?.target || '_self' }}"
  >
    <img
      class="logo__image"
      src="{{ mainNavigation?.brand?.image?.src }}"
      alt="{{ mainNavigation?.brand?.image?.alt }}"
    />
  </a>
  <div class="animation-wrapper" [class.is-collapsed]="!visible">
    <div class="navbar-main-nav navbar-collapse justify-content-end">
      <ul class="navbar-nav nav-level-0 meta-nav-mobile">
        @if (mainNavigation.action) {
          <li class="nav-item nav-item-action">
            <a
              routerLink="{{ mainNavigation.action.href }}"
              class="nav-link nav-link-meta"
              target="{{ mainNavigation.action.target }}"
              [class.active]="mainNavigation.action.href === route.url"
              >{{ mainNavigation.action.text }}</a
            >
          </li>
        }
      </ul>
      @for (item0 of mainNavigation.mainNavigationItems; track item0; let i = $index) {
        <ul class="navbar-nav nav-level-0" [class.active]="isActiveMenu(item0)">
          @if (item0.items) {
            <a routerLink="{{ item0.href }}" class="nav-link nav-link-meta" [class.active]="isActiveMenu(item0)">{{
              item0.text
            }}</a>
          }
          @if (!item0.items) {
            <a
              routerLink="{{ item0.href }}"
              class="nav-link nav-link-meta"
              target="{{ item0.target }}"
              [class.active]="item0.href === route.url"
              >{{ item0.text }}</a
            >
          }
          <li class="nav-item meta-nav-mobile">
            <a
              routerLink="{{ item0.href }}"
              class="nav-link meta-nav-mobile-item"
              target="{{ item0.target }}"
              [class.active]="item0.href === route.url"
              >Übersicht</a
            >
          </li>
          @for (item1 of item0.items; track item1; let j = $index) {
            <li class="nav-item dropdown" [class.active]="isActive(1, j)">
              @if (item1.items && isLgDown) {
                <div class="nav-overlay nav-overlay-level-0"></div>
              }
              @if (item1.items) {
                <a
                  href="#"
                  class="nav-link dropdown"
                  (click)="$event.preventDefault(); toggleSubnav(1, j)"
                  [class.active]="isActive(1, j)"
                  [class.marked]="isActiveItem(item1)"
                  >{{ item1.text }}</a
                >
              }
              @if (!item1.items && item1.href.includes("http")) {
                <a
                  href="{{ item1.href }}"
                  class="nav-link"
                  [class.active]="isActive(1, j)"
                  [class.marked]="isActiveItem(item1)"
                  >{{ item1.text }}</a
                >
              }
              @if (!item1.items && !item1.href.includes("http")) {
                <a
                  routerLink="{{ item1.href }}"
                  class="nav-link"
                  [class.active]="isActive(1, j)"
                  [class.marked]="isActiveItem(item1)"
                  >{{ item1.text }}</a
                >
              }
              @if (item1.items) {
                <ul
                  [@flyIn]="isActive(1, j) + '' + isLgDown"
                  class="nav-level-2 navbar-nav-sub"
                  [class.active]="isActive(1, j)"
                >
                  @if (item1.items && isLgDown) {
                    <li class="nav-item nav-item-level-2 nav-item-back-top">
                      <a
                        href="#"
                        class="nav-link nav-link-back-top"
                        (click)="$event.preventDefault(); toggleSubnav(1, j)"
                        >Zurück zu {{ item0.text }}</a
                      >
                    </li>
                  }
                  <li class="nav-item nav-item-level-2 nav-item-back">
                    <a
                      routerLink="{{ item1.href }}"
                      class="nav-link"
                      target="{{ item1.target }}"
                      [class.active]="item1.href === route.url"
                      >{{ isLgDown ? item1.text : "Übersicht" }}</a
                    >
                  </li>
                  @for (item2 of item1.items; track item2; let k = $index) {
                    <li class="nav-item nav-item-level-2" [class.active]="isActive(2, k)">
                      @if (item2.items && isLgDown) {
                        <div class="nav-overlay nav-overlay-level-1"></div>
                      }
                      @if (item2.items && isLgDown) {
                        <a
                          href="#"
                          class="nav-link dropdown"
                          (click)="$event.preventDefault(); toggleSubnav(2, k)"
                          [class.active]="item2.href === route.url"
                          >{{ item2.text }}</a
                        >
                      }
                      @if (!item2.items || isLgDown === false) {
                        <a routerLink="{{ item2.href }}" class="nav-link" [class.active]="item2.href === route.url">{{
                          item2.text
                        }}</a>
                      }
                      @if (item2.items) {
                        <ul [@flyIn]="isActive(2, k) + '' + isLgDown" class="nav-level-3">
                          @if (item2.items && isLgDown) {
                            <li class="nav-item nav-item-level-3 nav-item-back-top">
                              <a
                                href="#"
                                class="nav-link nav-link-level-3 nav-link-back-top"
                                (click)="$event.preventDefault(); toggleSubnav(2, k)"
                                [class.marked]="isActiveItem(item2)"
                                >Zurück zu {{ item1.text }}</a
                              >
                            </li>
                          }
                          <li class="nav-item nav-item-level-3 nav-item-back">
                            <a
                              routerLink="{{ item2.href }}"
                              class="nav-link"
                              target="{{ item2.target }}"
                              [class.active]="item2.href === route.url"
                              >{{ item2.text }}</a
                            >
                          </li>
                          @for (item3 of item2.items; track item3) {
                            <li class="nav-item nav-item-level-3">
                              <a
                                routerLink="{{ item3.href }}"
                                class="nav-link nav-link-level-3"
                                target="{{ item3.target }}"
                                [class.active]="item3.href === route.url"
                                >{{ item3.text }}</a
                              >
                            </li>
                          }
                        </ul>
                      }
                    </li>
                  }
                </ul>
              }
            </li>
          }
          @if (mainNavigation.action) {
            <li class="nav-item nav-item-action">
              <a
                routerLink="{{ mainNavigation.action.href }}"
                routerLinkActive="active"
                class="nav-link nav-link-cta"
                target="{{ mainNavigation.action.target || '_self' }}"
              >
                <div>{{ mainNavigation.action.text }}</div>
              </a>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</nav>
