import { provideState } from "@ngrx/store";
import type { HeaderConfiguration } from "./interfaces/header-configuration.interface";
import * as fromHeader from "./+state/header.reducer";
import { HEADER_CONFIGURATION } from "./tokens/header-configuration.token";

export function provideHeader(configuration: HeaderConfiguration = {}) {
  return [
    provideState(fromHeader.HEADER_FEATURE_KEY, fromHeader.reducer),
    { provide: HEADER_CONFIGURATION, useValue: configuration }
  ];
}
