import type { FooterConfiguration } from "./interfaces/footer-configuration.interface";
import type { Provider } from "@angular/core";
import { FOOTER_CONFIGURATION } from "./tokens/footer-configuration.token";

export function provideFooter(config: FooterConfiguration = { isCobrandedB2C: false }): Provider[] {
  return [
    {
      provide: FOOTER_CONFIGURATION,
      useValue: config
    }
  ];
}
