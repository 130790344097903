<div class="footer-meta" [ngClass]="{ '!bg-cg-red-dark-20': hasError }">
  <div class="container-fluid">
    <div class="row">
      @if (showMetaFooter && meta) {
        <div class="social-wrapper">
          <div class="social social-footer d-flex align-items-center">
            <div class="social-text" [ngClass]="{ '!text-white': hasError }">{{ meta.social.text }}</div>
            <ul class="list-unstyled d-flex flex gap-4 d:ml-4">
              @for (link of meta.social.links; track link) {
                <li>
                  <a
                    class="text-cg-anthracite-light-80 hover:text-white"
                    href="{{ link.href }}"
                    target="{{ link.target }}"
                    title="{{ link.title }}"
                  >
                    <cg-icon
                      class="max-m:first max-m:first:ml-0 m-2 box-border h-6 w-6 d:x-[m-0,h-6,w-6]"
                      [content]="link.icon"
                      [ngClass]="{ '[&>mat-icon>svg]:fill-white': hasError }"
                    ></cg-icon>
                  </a>
                </li>
              }
            </ul>
          </div>
        </div>
      }
      <div
        class="meta-navigation-wrapper"
        [ngClass]="{ 'meta-navigation-wrapper-microsite [@media(max-width:675px)]:pt-4': !showMetaFooter }"
      >
        <cg-meta-navigation
          class="meta-navigation"
          [metaNavigation]="meta.metaNavigation"
          [metaNavClass]="meta.metaNavigationClass"
          [route]="route"
          [hasError]="hasError"
        ></cg-meta-navigation>
      </div>
    </div>
  </div>
</div>
