// TODO

import { Injectable } from "@angular/core";
import { mergeMap, Observable, of, take } from "rxjs";
import { SpinnerMode } from "@cg/spinner";
import { ApiService } from "@cg/core/api";
import { NavigationFacade } from "../+state/navigation.facade";
import { NavigationStructure } from "../interfaces/navigation-structure.interface";

@Injectable({
  providedIn: "root"
})
export class NavigationService {
  public constructor(
    private readonly apiService: ApiService,
    private readonly navigationFacade: NavigationFacade
  ) {}

  public getHeaderNavigationStructure(): Observable<NavigationStructure> {
    const pathPart = "/?depth=4";

    return this.navigationFacade.header$.pipe(
      take(1),
      mergeMap((header: NavigationStructure) => {
        if (header) {
          return of(header);
        }

        return this._getNavigationStructure(pathPart);
      })
    );
  }

  public getFooterNavigationStructure(): Observable<NavigationStructure> {
    const pathPart = "/footer";

    return this.navigationFacade.footer$.pipe(
      take(1),
      mergeMap((footer: NavigationStructure) => {
        if (footer) {
          return of(footer);
        }

        return this._getNavigationStructure(pathPart);
      })
    );
  }

  private _getNavigationStructure(pathPart: string): Observable<NavigationStructure> {
    return this.apiService.get("navigationcontent", pathPart, { spinnerMode: SpinnerMode.NONE });
  }
}
