import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as HeaderActions from "./header.actions";
import { HeaderPartialState } from "./header.reducer";
import * as HeaderSelectors from "./header.selectors";

@Injectable({
  providedIn: "root"
})
export class HeaderFacade {
  public coronaToastHidden$ = this.store.pipe(select(HeaderSelectors.getCoronaToastHidden));
  public busyBannerHidden$ = this.store.pipe(select(HeaderSelectors.getBusyBannerHidden));

  public constructor(private store: Store<HeaderPartialState>) {}

  public setCoronaToastHidden() {
    this.store.dispatch(HeaderActions.setCoronaToastHidden());
  }

  public setBusyBannerHidden() {
    this.store.dispatch(HeaderActions.setBusyBannerHidden());
  }
}
