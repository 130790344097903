import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ToastMessage, ToastMessageComponent } from "@cg/toast";
import { alertIcon } from "@cg/icon";
import { HeaderFacade } from "../../+state/header.facade";

@Component({
  selector: "cg-busy-banner",
  templateUrl: "./busy-banner.component.html",
  styleUrls: ["./busy-banner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToastMessageComponent]
})
export class BusyBannerComponent {
  public toastBusyContent: ToastMessage = {
    title: "",
    message: "Wir sind beschäftigter als gewöhnlich. <strong>Sichern Sie sich einen der wenigen Termine.</strong>",
    color: "#e60f04",
    animated: true,
    closable: true,
    icon: alertIcon,
    infoLink: {
      text: "Zur Online-Buchung",
      href: "/olb"
    }
  };

  public constructor(private headerFacade: HeaderFacade) {}

  public bannerClosed() {
    this.headerFacade.setBusyBannerHidden();
  }
}
