<div class="relative flex h-full w-full flex-col" (click)="toggle()">
  <div
    class="absolute h-0.5 w-4 origin-center transform bg-cg-anthracite transition-transform duration-500"
    [ngClass]="{ 'translate-y-1.5 rotate-45': visible }"
  ></div>
  <div
    class="absolute top-1.5 h-0.5 w-4 bg-cg-anthracite transition-opacity duration-500"
    [ngClass]="{ 'opacity-0': visible }"
  ></div>
  <div
    class="absolute top-3 h-0.5 w-4 origin-center transform bg-cg-anthracite transition-transform duration-500"
    [ngClass]="{ '-translate-y-1.5 -rotate-45': visible }"
  ></div>
</div>
