import { NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { RouterLink } from "@angular/router";
import { HeadlineComponent, HeadlineType, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { closeIcon, successIcon } from "@cg/icon";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import { ToastMessage } from "./interfaces/toast-message.interface";

@Component({
  selector: "cg-toast-message",
  templateUrl: "./toast-message.component.html",
  styleUrls: ["./toast-message.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, NgClass, IconComponent, ParagraphComponent, HeadlineComponent, RouterLink]
})
export class ToastMessageComponent {
  private _content: ToastMessage;

  protected readonly headlineType = HeadlineType;

  @Input() public set content(value: ToastMessage) {
    const icon = value?.icon ?? successIcon;

    this._content = { ...value, icon };
  }

  public get content(): ToastMessage {
    return this._content;
  }

  @Output()
  public closed = new EventEmitter();

  public closeIcon: Icon = closeIcon;

  public constructor(private elRef: ElementRef) {}

  public async close() {
    const el = this.elRef.nativeElement as HTMLDivElement;

    el.classList.add("closed");

    if (this.content.animated) {
      el.classList.add("animated");
    }

    await Promise.all(el.getAnimations().map((animation: Animation) => animation.finished));

    this.closed?.emit();
  }
}
