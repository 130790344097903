import { animate, state, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  inject,
  Input,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RouterLink } from "@angular/router";
import { MainNavigation, MainNavigationItem } from "@cg/core/interfaces";
import { BreakpointService } from "@cg/shared";
import type { Link } from "@cg/content-api/typescript-interfaces";
import { Level, Route } from "../../interfaces/route.interface";
import { ToggleButtonComponent } from "../toggle-button/toggle-button.component";

@Component({
  selector: "cg-main-micro-navigation",
  templateUrl: "./main-micro-navigation.component.html",
  styleUrls: ["./main-micro-navigation.component.scss"],
  animations: [
    trigger("flyIn", [
      state("truetrue", style({ opacity: 1, transform: "translateX(0)" })),
      transition("* => truetrue", [
        style({
          opacity: 0,
          transform: "translateX(100%)"
        }),
        animate("300ms ease-in")
      ]),
      state("falsetrue", style({ opacity: 0, transform: "translateX(100%)" })),
      transition("* => falsetrue", [
        style({
          opacity: 1,
          transform: "translateX(0)"
        }),
        animate("300ms ease-in")
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToggleButtonComponent, RouterLink]
})
export class MainMicroNavigationComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public _mainNavigation: MainNavigation;
  public activeMenuItem: MainNavigationItem;
  public kontakt: Link = {
    text: "Kontakt",
    href: "/kontakt"
  };
  @Input()
  public currentNavigation;
  @Input()
  public set mainNavigation(mainNavigation: MainNavigation) {
    this._mainNavigation = mainNavigation;
    this.setCurrentMenu(this.mainNavigation.mainNavigationItems);
    this.setParentNavItems();
  }

  public get mainNavigation(): MainNavigation {
    return this._mainNavigation;
  }

  public activeNavItem: MainNavigationItem;
  public activeNavItemParents: MainNavigationItem[];
  public isLgDown: boolean;

  @Input()
  public set route(route: Route) {
    this._route = route;
    this.setParentNavItems();
    this.hideNavIfNoChildren();
  }

  public get route(): Route {
    return this._route;
  }

  public levels: Level[] = [0, -1, -1, -1];

  public visible = false;

  private _route: Route;

  public constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly breakpointService: BreakpointService
  ) {}

  public ngOnInit(): void {
    this.breakpointService.isUntilWidescreen$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLgDown: boolean) => {
        this.isLgDown = isLgDown;
        this.cdr.markForCheck();
      });
  }

  public setCurrentMenu(menu: MainNavigationItem[]) {
    if (this.route) {
      const reg = /^\/(\w*)/;
      let found = this.route.url.toString().match(reg)[0];
      if (found === "/autoglas") {
        found = "/";
      }
      const tempMenu = menu.find((item: MainNavigationItem) => item.href === found);
      if (tempMenu) {
        this.levels[0] = menu.findIndex((item: MainNavigationItem) => item.href === found) as Level;
      }
      if (tempMenu && (!this.activeMenuItem || this.activeMenuItem !== menu)) {
        this.activeMenuItem = tempMenu;
      } else if (!this.activeMenuItem) {
        this.activeMenuItem = menu.find((item: MainNavigationItem) => item.href === "/");
      }
    }
  }

  public onOpen() {
    if (this.currentNavigation.currentNavPath.path.length > 1) {
      this.restoreNavState(1, this.mainNavigation.mainNavigationItems[this.levels[0]].items);
    }
    this.visible = true;
  }

  public restoreNavState(level: number, currentItem: MainNavigationItem[]) {
    if (currentItem && this.currentNavigation.currentNavPath.path) {
      const tempItemIndex = currentItem.findIndex(
        (item: MainNavigationItem) => item.href === this.currentNavigation.currentNavPath.path[level].href
      );
      this.levels[level] = tempItemIndex as Level;
      if (level < this.currentNavigation.currentNavPath.path.length - 1) {
        this.restoreNavState(level + 1, currentItem[tempItemIndex].items);
      }
    }
  }

  public onClose() {
    this.visible = false;
  }

  @HostListener("document:click", ["$event"])
  public clickout(event: Event): void {
    const target = event.target as HTMLElement;
    const className = target.className;
    try {
      if (
        className.indexOf("nav-link dropdown") === -1 &&
        className.indexOf("hamburger-inner") === -1 &&
        className.indexOf("hamburger-box") === -1 &&
        className.indexOf("nav-link-back-top") === -1
      ) {
        this.levels[1] = -1;
      }
    } catch (e) {
      return void 0;
    }
  }

  public toggleSubnav(level: number, index: number): void {
    try {
      this.levels[level] = this.levels[level] === index && level !== 0 ? -1 : (index as Level);

      if (level === 0) {
        this.levels[1] = -1;
      }
      if (level === 1) {
        this.levels[2] = -1;
      }
      if (level === 2) {
        this.levels[3] = -1;
      }
    } catch (e) {
      return void 0;
    }
  }

  public isActive(level: number, index: number): boolean {
    return this.levels[level] === index;
  }

  public isActiveMenu(item: MainNavigationItem) {
    if (
      this.currentNavigation &&
      this.currentNavigation.currentNavPath &&
      this.currentNavigation.currentNavPath.path &&
      this.currentNavigation.currentNavPath.path.length > 0
    ) {
      return this.currentNavigation.currentNavPath.path[0].href === item.href;
    } else if (item && item.href === "/") {
      return true;
    }
  }

  public isActiveItem(item: MainNavigationItem): boolean {
    if (!this.route) {
      return false;
    }
    if (item.href === this.route.url) {
      return true;
    }
    return false;
  }

  public findActiveNavItem(items: MainNavigationItem[]): void {
    if (!this.route || !items) {
      return;
    }
    for (const item of items) {
      if (item.href === this.route.url) {
        return;
      }
      if (item.items) {
        this.findActiveNavItem(item.items);
      }
    }
  }

  public setParentNavItems(): void {
    this.activeNavItem = null;
    this.findActiveNavItem(this.mainNavigation.mainNavigationItems);
    this.activeNavItemParents = [];
  }

  public hideNavIfNoChildren(): void {
    this.visible = false;
  }
}
