import { NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  Input,
  OnInit,
  Optional
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter, take, tap } from "rxjs";
import { Footer, FooterNavigation } from "@cg/core/interfaces";
import { FooterNavigationComponent, NavigationFacade, NavigationItem, NavigationStructure } from "@cg/navigation";
import { CtaComponent } from "@cg/shared";
import { FooterConfiguration } from "../../interfaces/footer-configuration.interface";
import { FOOTER_CONFIGURATION } from "../../tokens/footer-configuration.token";
import { MetaFooterComponent } from "../meta-footer/meta-footer.component";
import { footerContent } from "./model/footer-content.model";

const BASE_URL = "https://carglass.de";

@Component({
  selector: "cg-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, CtaComponent, FooterNavigationComponent, MetaFooterComponent]
})
export class FooterComponent implements OnInit {
  @Input()
  public footer: Footer;

  @Input()
  public disableOlbCta = false;

  @Input()
  public hideMainFooter = false;

  @Input()
  public route: string;

  public hasError = false;

  private isCobrandendB2C: boolean;

  private destroyRef: DestroyRef = inject(DestroyRef);

  public constructor(
    private readonly navigationFacade: NavigationFacade,
    @Optional() @Inject(FOOTER_CONFIGURATION) private readonly configuration: FooterConfiguration,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.isCobrandendB2C = this.configuration?.isCobrandedB2C;
  }

  public ngOnInit() {
    if (!this.hideMainFooter) {
      this.navigationFacade.getFooterNavigationStructure();

      this.navigationFacade.footer$
        .pipe(
          filter((footer: NavigationStructure) => !!footer),
          take(1),
          tap((footer: NavigationStructure) => this.createFooterWithNavigations(this.mapNavigationStructure(footer))),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    } else {
      this.createFooterWithNavigations(this.configuration?.model?.navigations ?? []);
    }

    this.navigationFacade.footerError$
      .pipe(
        filter((error: string) => !!error),
        tap(() =>
          this.createFooterWithNavigations(this.configuration?.model?.navigations ?? footerContent.navigations)
        ),
        tap(() => {
          this.hasError = true;
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  // TODO: remove this mapping function, mapping should be done in backend
  public mapNavigationStructure(navigationStructure: NavigationStructure): FooterNavigation[] | undefined {
    if (!navigationStructure.pages) {
      return;
    }

    return navigationStructure.pages.map((topic: NavigationItem) => ({
      headline: topic.properties[0].values[0],
      href: topic.link,
      links: topic.pages
        ? topic.pages.map((link: NavigationItem) => ({
            text: link.properties[0].values[0],
            href: !link.link.includes("http") && this.isCobrandendB2C ? `${BASE_URL}${link.link}` : link.link
          }))
        : []
    }));
  }

  private createFooterWithNavigations(navigations: FooterNavigation[]): void {
    this.footer = {
      /* eslint-disable @typescript-eslint/no-extra-parens */
      ...(this.configuration?.model ?? footerContent),
      navigations
    };
    this.cdr.markForCheck();
  }
}
