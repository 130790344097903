import { carglassLogo } from "@cg/icon";
import type { Header } from "@cg/core/interfaces";

export const headerContent: Header = {
  mainNavigation: {
    brand: {
      link: {
        text: "carglass",
        href: "/"
      },
      image: { ...carglassLogo, alt: "Carglass Logo" }
    },
    mainNavigationItems: [
      {
        title: "Leistungen",
        text: "Leistungen",
        href: "/leistungen",
        target: "_self"
      },
      {
        title: "Kosten",
        text: "Kosten",
        href: "/kosten",
        target: "_self"
      }
    ],
    location: {
      text: "Standorte",
      href: "/standorte"
    },
    appointment: {
      text: "MeinCarglass<sup>®</sup>",
      href: "/my-carglass",
      target: "_self"
    },
    repair: {
      text: "Schaden prüfen",
      href: "/olb",
      target: "_self"
    },
    myCarglassLogout: {
      text: "Abmelden",
      href: "/my-carglass/logout",
      target: "_self"
    }
  },
  metaNavigation: [
    {
      text: "Das Unternehmen",
      href: "/unternehmen"
    },
    {
      text: "Kontakt",
      href: "/kontakt"
    }
  ]
};
