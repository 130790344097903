import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { GoogleTagManagerService } from "@cg/analytics";
import { MainNavigationItem } from "@cg/core/interfaces";
import { IconComponent } from "@cg/core/ui";
import { logoutIcon } from "@cg/icon";

@Component({
  selector: "cg-logout-item",
  templateUrl: "./logout-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent]
})
export class LogoutItemComponent {
  @Input()
  public hideLinkElement = false;

  @Input()
  public item: MainNavigationItem;

  public readonly logoutIcon = logoutIcon;

  public constructor(
    private readonly router: Router,
    private gtmService: GoogleTagManagerService
  ) {}

  public navigateTo(url: string): void {
    this.router.navigate([url]).then(() => {});
    this.gtmService.trackEvent({
      eventCategory: "my-carglass",
      eventAction: "logout",
      eventLabel: "my-carglass/detail"
    });
  }
}
