import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MetaFooter } from "@cg/core/interfaces";
import { IconComponent } from "@cg/core/ui";
import { environment } from "@cg/environments";
import { MetaNavigationComponent } from "@cg/navigation";
import { ApplicationName } from "@cg/core/enums";

@Component({
  selector: "cg-meta-footer",
  templateUrl: "./meta-footer.component.html",
  styleUrls: ["./meta-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, IconComponent, MetaNavigationComponent]
})
export class MetaFooterComponent {
  @Input()
  public meta: MetaFooter;

  @Input()
  public route: string;

  @Input()
  public hasError = false;

  public application = ApplicationName;
  public readonly showMetaFooter = environment.features.showMetaFooter;
}
